import { YuLifeLocale } from "@hooks";
import { ContentType } from "./_types";
import { correctYuLifeUrls } from "./correctYuLifeUrls";
import { BlogDataQuery } from "@graphql";

const breakpointsInOrder = [
  "xxlarge",
  "xlarge",
  "large",
  "medium",
  "small",
  "thumbnail",
  "xsmall",
] as const;

type StrapiImageBreakpoint = (typeof breakpointsInOrder)[number];

interface StrapiImageFormatData {
  ext?: string;
  hash?: string;
  height?: number;
  mime?: string;
  name?: string;
  path?: string;
  size?: number;
  url: string;
  width?: number;
}

type OptionalImageFormats = {
  [Key in StrapiImageBreakpoint]?: Partial<StrapiImageFormatData>;
};

const ENGLISH_CONTENT_TYPE_MAP: Record<ContentType, string> = {
  blog: "Blogs",
  caseStudies: "Case Studies",
  newsAndEvents: "News And Events",
};

const JAPANESE_CONTENT_TYPE_MAP: Record<ContentType, string> = {
  blog: "ブログ",
  caseStudies: "他社成功事例",
  newsAndEvents: "ニュース・イベント",
};

export const getStrapiImageByBreakpoint = (
  formats: OptionalImageFormats,
  breakpoint: StrapiImageBreakpoint = "xlarge",
) => {
  const chosenBreakpointIndex = breakpointsInOrder.findIndex((el) => el === breakpoint);
  const acceptableBreakpoints = breakpointsInOrder.slice(chosenBreakpointIndex);
  for (const size of acceptableBreakpoints) {
    if (formats?.[size]?.url) {
      return formats[size].url;
    }
  }

  return "";
};

export const makeAnchorsOpenNewTab = (str: string): string => {
  return (str || "").replace(
    /<a (?=href="[^\"]*">)/g,
    `<a target="_blank" rel="noopener noreferrer"`,
  );
};

export const caseStudySlugList = [
  "using-employee-benefits-to-attract-top-talent",
  "hooray-health-and-protection-engage-health-group-yulife-the-fitness-february-challenge",
  "vanti-case-study-wellbeing-benefits-roi",
  "how-yulife-changed-my-life-for-the-better",
  "bringing-my-best-self-to-work-with-yulife",
  "providing-protection-for-my-family-with-yulife",
  "connecting-with-my-colleagues-through-yulife",
  "saving-money-by-living-well-with-yulife",
  "walking-my-way-to-better-health-with-yulife",
  "a-look-inside-co-ops-market-leading-wellbeing-programme",
  "capital-one-onboards-yulife",
  "capitalising-on-employee-wellbeing-with-yulife-and-fiscale",
  "yulife-makes-a-material-difference-to-employee-wellbeing-at-orthoplastics",
  "meantime-communications-partner-with-yulife-to-promote-employee-wellbeing",
  "yulife-inspires-healthy-performance-among-employees-at-vodafone-franchise-stores",
  "yulife-supports-fast-paced-fyxer-through-covid-19",
  "xdesigns-friendly-and-inclusive-culture-is-the-perfect-fit-for-yulife-2",
  "berks-bucks-oxon-wildlife-trust-protect-their-people-with-yulife",
  "cultivating-a-healthier-workplace-with-del-monte-uk",
  "partnering-to-reinforce-protect-and-engage-employees",
  "putting-employee-mental-health-first-with-yulife-and-belvoir-leamington-spa",
  "supporting-employees-through-adversity-with-yulife-and-harbr",
  "insurance-broker-and-wealth-management-firm-drewberry-enjoys-employee-benefits-boost-from-yulife",
  "data-driven-employee-insights-and-engagement-with-yulife-and-tiney",
  "bringing-wellness-to-the-workplace-how-infostretch-delivered-health-and-wellbeing-benefits-worth-over-7x-its-insurance-premium",
  "tour-de-fladgate",
  "signal-ai-increasing-employee-engagement",
  "severn-trent-case-study ",
  "jacqueline-at-bluebird-care-east-devon-gives-yulife-her-stamp-of-appreciation",
  "bringing-employee-benefits-to-the-forefront-with-yulife-and-fladgate",
  "xdesign-case-study",
  "shifting-culture-in-the-startup-space-with-culture-shift",
  "employees-at-vodafone-franchise-embrace-the-yulife-app",
];

export const sanitisePostData = (post: BlogDataQuery["blogPosts"]["data"][0]) => {
  return {
    id: post.id,
    type: post.attributes.__typename.toLowerCase(),
    ...post.attributes,
    post: correctYuLifeUrls(post.attributes.post),
  };
};

export const formatEnglishContentType = (contentType: ContentType): string => {
  return ENGLISH_CONTENT_TYPE_MAP[contentType] || contentType;
};

export const formatJapaneseContentType = (contentType: ContentType): string => {
  return JAPANESE_CONTENT_TYPE_MAP[contentType] || contentType;
};

export const localiseContentType = (contentType: ContentType, locale: YuLifeLocale): string => {
  if (locale === "jp") {
    return formatJapaneseContentType(contentType);
  }
  return formatEnglishContentType(contentType);
};
