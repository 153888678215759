export enum StaticRoutes {
  error = "/404/",
  aboutUs = "/about-us/",
  adviser = "/adviser/",
  adviserContactUs = "/adviser/contact-us/",
  adviserSuccess = "/adviser/contact-us/success/",
  blog = "/blog/",
  careers = "/careers/",
  caseStudies = "/case-studies/",
  contactUs = "/contact-us/",
  deathInService = "/death-in-service/",
  featuresEmployeeApp = "/features/employee-app/",
  featuresEmployeeAssistanceProgramme = "/features/employee-assistance-programme/",
  // featuresEmployeeAssistanceProgram = "/features/employee-assistance-program/",
  featuresEmployeeBenefits = "/features/employee-benefits/",
  featuresEmployeeEngagement = "/features/employee-engagement/",
  featuresHrInsights = "/features/hr-insights/",
  featuresPortal = "/features/portal/",
  featuresRewards = "/features/rewards/",
  featuresGlobalRewardsAndServices = "/features/global-rewards-and-services/",
  featuresSocialImpactBenefits = "/features/social-impact-benefits/",
  featuresVirtualGpServices = "/features/virtual-gp-services/",
  featuresWellbeingHub = "/features/wellbeing-hub/",
  featuresYustore = "/features/yustore/",
  formProductDetails = "form/product-details/",
  formSuccess = "form/success/",
  getAQuote = "/get-a-quote/",
  getAQuoteSuccess = "/get-a-quote/success/",
  groupInsuranceForms = "/group-insurance-forms/",
  hrInsights = "/hr-insights/",
  iframeGroupLifeForm = "/iframe/group-life-form/",
  iframeQuoteForm = "/iframe/quote-form/",
  index = "/",
  inspireLives = "/inspire-lives/",
  insuranceAppOnly = "/insurance/app-only/",
  // insuranceEmployerSponsoredInsurance = "/insurance/employer-sponsored-insurance/",
  insuranceGroupCriticalIllness = "/insurance/group-critical-illness/",
  insuranceGroupDentalInsurance = "/insurance/group-dental-insurance/",
  insuranceGroupIncomeProtection = "/insurance/group-income-protection/",
  insuranceGroupInsurance = "/insurance/group-insurance/",
  insuranceGroupLifeInsurance = "/insurance/group-life-insurance/",
  // insuranceGroupDisabilityInsurance = "/insurance/group-disability-insurance/",
  insuranceVoluntaryInsurance = "/insurance/voluntary-insurance/",
  insuranceGroupHealthInsurance = "/insurance/group-health-insurance/",
  // insuranceGroupVisionInsurance = "/insurance/group-vision-insurance/",
  insuranceFuneralCover = "/insurance/funeral-cover/",
  insuranceLumpSumDisability = "/insurance/lump-sum-disability/",
  insuranceEducationBenefit = "/insurance/education-benefit/",
  newsAndEvents = "/news-and-events/",
  ourSolutionsBrokersAndAdvisers = "/our-solutions/brokers-and-advisers",
  ourSolutionsBusinesses = "/our-solutions/businesses",
  ourSolutionsIndividuals = "/our-solutions/individuals",
  ourSolutionsInsuranceCarriers = "/our-solutions/insurance-carriers",
  personalLifeInsurance = "/personal-life-insurance/",
  privateHealthInsurance = "/private-health-insurance/",
  press = "/press/",
  productDocDownload = "/product-doc-download/",
  productDocDownloadThankYou = "/product-doc-download/thank-you/",
  quizLeaderPersonality = "/quiz/leader-personality/",
  referral = "/resources/referral/",
  referralThankYou = "/resources/referred-thank-you/",
  resourceHub = "/resource-hub/",
  rewardsRedeemedCharityWater = "/rewards/redeemed/charity-water/",
  rewardsRedeemedEarthly = "/rewards/redeemed/earthly/",
  rewardsRedeemedFoodForward = "/rewards/redeemed/food-forward/",
  rewardsRedeemedOceanCleanup = "/rewards/redeemed/ocean-cleanup/",
  rewardsRedeemedShareTheMeal = "/rewards/redeemed/share-the-meal/",
  rewardsRedeemedCheckEmail = "/rewards/redeemed/check-email/",
  rewardsRedeemedYoyoVoucher = "/rewards/redeemed/yoyo-voucher/",
  team = "/team/",
  whatsIncluded = "/whats-included/",
  wills = "/wills/",
  ycp20200117Calm = "/ycp20200117/calm/",
  ycp20210423Farewill = "/ycp20210423/farewill/",
}
