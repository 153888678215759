"use client";

import { useMemo } from "react";
import { useWindowSize } from "./useWindowSize";

export const useBreakpoints = () => {
  const [width] = useWindowSize();

  const { isMobile, isTablet, isDesktop } = useMemo(() => {
    const isMobile = width <= 720;
    const isTablet = width <= 1040 && width >= 720;
    const isDesktop = width >= 1040;

    return { isMobile, isTablet, isDesktop };
  }, [width]);

  return { isMobile, isTablet, isDesktop };
};
