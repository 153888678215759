import { YuLifeLocale } from "hooks";
import { StaticRoutes } from "./StaticRoutes";
import { staticRoutesByLocale as defaultRoutesByLocale } from "./staticRoutesByLocale";
import { ReactElement } from "react";
import { join } from "path-browserify";
import { constructHref, getLanguageForHrefLang } from "utils/hreflang";

export function createHrefLangs(
  route: string,
  currentLocale: YuLifeLocale,
  staticRoutesByLocale: Record<StaticRoutes, YuLifeLocale[]> = defaultRoutesByLocale,
): ReactElement[] {
  const href = constructHref(currentLocale, route);

  const locales = staticRoutesByLocale[route]?.filter(Boolean) || [];

  if (!locales.length) {
    return [<link key="canonical" rel="canonical" href={href} />];
  }

  return [
    <link key="canonical" rel="canonical" href={href} />,
    ...locales.map((locale: YuLifeLocale) => {
      const href = constructHref(locale, route);

      return (
        <link key={href} rel="alternate" href={href} hrefLang={getLanguageForHrefLang(locale)} />
      );
    }),
    ...(locales.length > 1
      ? [
          <link
            key="alternate"
            rel="alternate"
            href={constructHref("uk", route)}
            hrefLang="x-default"
          />,
        ]
      : []),
  ];
}
