import { YuLifeLocale } from "@hooks";
import { join } from "path-browserify";

const localeToHrefLangMap: Record<YuLifeLocale, string> = {
  uk: "en-GB",
  us: "en-US",
  za: "en-ZA",
  jp: "jv-JP",
};

export const getLanguageForHrefLang = (locale: YuLifeLocale) => {
  return localeToHrefLangMap[locale];
};

export const constructHref = (locale: YuLifeLocale, route: string) => {
  const url =
    process.env.BASE_URL + join(locale === "uk" ? "/" : `/${locale}/`, route === "/" ? "" : route);

  const hrefWithTrailingSlash =
    url.endsWith("/") || url === process.env.BASE_URL || url.endsWith("3005") ? url : url + "/";

  return hrefWithTrailingSlash;
};
