"use client";

import { ForwardedRef } from "react";

export const useForwardRef = <T>(ref: ForwardedRef<T>) => {
  if ("current" in ref) {
    return ref;
  }

  return { current: undefined };
};
