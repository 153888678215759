import { ComponentType, FormHTMLAttributes } from "react";

/**
 * This is useful when you want to treat third-party form components (like Unform's <Form>) as if they were regular HTML form elements, ensuring compatibility with styled-components and avoiding unnecessary TypeScript errors about missing DOM props.
 */
export type FormType = ComponentType<FormHTMLAttributes<HTMLFormElement>>;

export interface SeoPageType {
  slug?: string | null;
  title?: string | null;
  html?: string | null;
  feature_image?: string | null;
  codeinjection_head?: string | null;
  codeinjection_foot?: string | null;
  custom_template?: string | null;
  canonical_url?: string | null;
  tags?: any[] | null;
  primary_tag?: any;
  url?: string | null;
  page?: boolean | null;
  excerpt?: string | null;
  og_image?: string | null;
  og_title?: string | null;
  og_description?: string | null;
  twitter_image?: string | null;
  twitter_title?: string | null;
  twitter_description?: string | null;
  meta_title?: string | null;
  meta_description?: string | null;
}

export enum HubspotForms {
  getInTouch = "getInTouch",
  adviserContactUs = "2024-02-New-Form-Advisor-Inbound",
  quote = "2020-06-New-Form-Quote-ALLProducts",
  quoteUS = "2024-08-New-USA-Website-Form-Inbound",
  quoteJP = "2024-JP-Saas+Insurance-Website (Get a quote/Sales Demo)",
  callback = "2020-06-New-Form-GetaCallback",
  instantPaidSearch = "2020-06-New-Form-Quote-Instant-LP-Paid-Search-GL",
  footer = "footer",
  blogSignUp = "Blog Signup Form",
  personalityQuiz = "Personality Quiz Lead Gen Form",
  productLaunchWaitingList = "PLI Product Launch Waiting List (9 November 2021)",
  quoteSpec = "Quote Spec | Product request & Company Info | Virgin Schemes",
  quoteSpecRisk = "Quote Spec | Product request & Company Info | Virgin Schemes (Risk)",
  quoteSpecHealth = "Quote Spec | Product request & Company Info | Virgin Schemes (Health)",
  groupHealthInsurance = "Quote Spec | GHI - Group Health Insurance | Virgin Schemes",
  groupLifeInsurance = "Quote Spec | GLI - Group Life Insurance | Virgin Schemes",
  groupIncomeProtection = "Quote Spec | GIP - Group Income Protection | Virgin Schemes",
  groupCriticalIllness = "Quote Spec | GCI - Group Critical Illness | Virgin Schemes",
  groupDentalInsurance = "Quote Spec | GDI - Group Dental Insurance | Virgin Schemes",
  productDocDownload = "2024-JP-Saas+Insurance-Website (Product DL)",
  instapageQuoteJP = "2024-JP-Saas-Instapage V2 (Get a quote/Sales Demo)",
  instapageProductDocDownload = "2024-JP-Saas-Instapage V2 (Product DL)",
  referralForm = "2023-08 Referral Campaign UK",
}

export interface CompaniesHouseCompaniesSearchResponse {
  address?: {
    address_line_1?: string;
    address_line_2?: string;
    care_of?: string;
    country?: string;
    locality?: string;
    po_box?: string;
    postal_code?: string;
    region?: string;
  };
  address_snippet?: string;
  company_number?: string;
  company_status?: string;
  company_type?: string;
  date_of_cessation?: Date | string;
  date_of_creation?: Date | string;
  description?: string;
  description_identifier?: number[];
  external_registration_number?: string;
  kind?: string;
  links?: {
    self?: string;
  };
  matches?: {
    address_snippet?: number[];
    snippet?: number[];
    title?: number[];
  };
  snippet?: string;
  title?: string;
}

export interface CompaniesHouseRegisteredOfficeAddress {
  address_line_1?: string;
  address_line_2?: string;
  care_of?: string;
  country?: string;
  locality?: string;
  po_box?: string;
  postal_code?: string;
  premises?: string;
  region?: string;
}

export interface CompaniesHouseCompanyInfoResponse {
  accounts?: {
    accounting_reference_date?: {
      day?: number;
      month?: number;
    };
    last_accounts?: {
      made_up_to?: Date | string;
      period_end_on?: Date | string;
      period_start_on?: Date | string;
      type?: string;
    };
    next_accounts?: {
      due_on?: Date | string;
      overdue?: boolean;
      period_end_on?: Date | string;
      period_start_on?: Date | string;
    };
    next_due?: Date | string;
    next_made_up_to?: Date | string;
    overdue?: boolean;
  };
  annual_return?: {
    last_made_up_to?: Date | string;
    next_due?: Date | string;
    next_made_up_to?: Date | string;
    overdue?: boolean;
  };
  branch_company_details?: {
    business_activity?: string;
    parent_company_name?: string;
    parent_company_number?: string;
  };
  can_file?: boolean;
  company_name?: string;
  company_number?: string;
  company_status?: string;
  company_status_detail?: string;
  confirmation_statement?: {
    last_made_up_to?: Date | string;
    next_due?: Date | string;
    next_made_up_to?: Date | string;
    overdue?: boolean;
  };
  date_of_cessation?: Date | string;
  date_of_creation?: Date | string;
  etag?: string;
  external_registration_number?: string;
  foreign_company_details?: {
    accounting_requirement?: {
      foreign_account_type?: string;
      terms_of_account_publication?: string;
    };
    accounts?: {
      account_period_from?: {
        day?: number;
        month?: number;
      };
      account_period_to?: {
        day?: number;
        month?: number;
      };
      must_file_within?: {
        months?: number;
      };
    };
    business_activity?: string;
    company_type?: string;
    governed_by?: string;
    is_a_credit_finance_institution?: boolean;
    originating_registry?: {
      country?: string;
      name?: string;
    };
    registration_number?: string;
  };
  has_been_liquidated?: boolean;
  has_charges?: boolean;
  has_insolvency_history?: boolean;
  is_community_interest_company?: boolean;
  jurisdiction?: string;
  last_full_members_list_date?: Date | string;
  links?: {
    charges?: string;
    filing_history?: string;
    insolvency?: string;
    officers?: string;
    persons_with_significant_control?: string;
    persons_with_significant_control_statements?: string;
    registers?: string;
    self?: string;
  };
  partial_data_available?: string;
  previous_company_names?: [
    {
      ceased_on?: Date | string;
      effective_from?: Date | string;
      name?: string;
    },
  ];
  registered_office_address?: CompaniesHouseRegisteredOfficeAddress;
  registered_office_is_in_dispute?: boolean;
  sic_codes?: string[];
  subtype?: string;
  type?: string;
  undeliverable_registered_office_address?: boolean;
}

export interface PostAttributes {
  title?: string;
  excerpt?: string;
  slug?: string;
  featuredImage?: {
    data?: {
      id?: string;
      attributes?: {
        formats?: any;
        alternativeText?: string;
      };
    };
  };
  post?: string;
  publishDate?: any;
  metaTitle?: string;
  metaDescription?: string;
  author?: {
    data?: {
      id?: string;
      attributes?: {
        name?: string;
        bio?: string;
        image?: {
          data?: {
            id?: string;
            attributes?: {
              formats?: string;
            };
          };
        };
      };
    };
  };
  category?: {
    data?: {
      id?: string;
      attributes?: {
        name?: string;
        slug?: string;
      };
    };
  };
  tags?: {
    data?: Array<{
      id?: string;
      attributes?: {
        name?: string;
        slug?: string;
      };
    }>;
  };
  isFeatured?: boolean;
  featuredOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  publishedAt?: string;
}

export type ProductCardDimensions = Record<
  string,
  {
    height: string;
    width: string;
    justifyContent: string;
  }
>;

export enum ContentType {
  blog = "blog",
  newsEvents = "newsAndEvents",
  caseStudy = "caseStudies",
}
