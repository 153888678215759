"use client";

import { usePathname } from "next/navigation";
import { useLocale, YuLifeLocale } from "./useLocale";

export const useHandleLocaleSwitch = () => {
  const pathname = usePathname();
  const { setLocale } = useLocale();

  // for avoiding a 404 when switching from one locale to another
  // if a corresponding url doesn't exist we default to the home page of the desired locale
  // this is mainly for webcrawlers/seo reasons, but is also improved ux
  async function checkPageExistsInLocale(country: YuLifeLocale, path: string) {
    try {
      const response = await fetch(
        `${process.env.BASE_URL}${country === "uk" ? "" : `/${country}`}${path}`,
        { method: "HEAD" },
      );
      return response.ok;
    } catch (error) {
      return false;
    }
  }

  async function handleLocaleSwitch(country: YuLifeLocale) {
    setLocale(country);
    const newPath = pathname.startsWith("/404") ? "/" : pathname;
    const urlBase = process.env.BASE_URL + (country === "uk" ? "" : `/${country}`);

    const pageExists = await checkPageExistsInLocale(country, newPath);

    const targetUrl = pageExists ? `${urlBase}${newPath}` : `${urlBase}/`;
    window.location.href = targetUrl;
  }

  return handleLocaleSwitch;
};
