"use client";

import React, { useEffect, useCallback, useState, useMemo, useRef, MutableRefObject } from "react";
import { tabbable, FocusableElement } from "tabbable";
import { useEventListener } from "./useEventListener";

// This hook was inspired by https://tobbelindstrom.com/blog/useTrapFocus/
interface UseTrapFocus {
  isActive: boolean;
  includeContainer?: boolean;
  returnFocus?: boolean;
  updateNodes?: boolean;
}

export const useTrapFocus = <T extends HTMLElement>(
  options?: UseTrapFocus,
): MutableRefObject<T> => {
  const node = useRef<T>(null);

  if (typeof window === "undefined") {
    return node;
  }

  const { includeContainer, returnFocus, updateNodes, isActive } = useMemo<UseTrapFocus>(
    () => ({
      includeContainer: false,
      returnFocus: true,
      updateNodes: false,
      isActive: true,
      ...options,
    }),
    [options],
  );
  const [tabbableNodes, setTabbableNodes] = useState<FocusableElement[]>([]);
  const previousFocusedNode = useRef<T>(document.activeElement as T);

  const updateTabbableNodes = useCallback(() => {
    const { current } = node;

    if (current) {
      const getTabbableNodes = tabbable(current, { includeContainer });
      setTabbableNodes(getTabbableNodes);
      return getTabbableNodes;
    }

    return [];
  }, [includeContainer]);

  useEffect(() => {
    updateTabbableNodes();
    // when isActive changes, re-update tabbable nodes
  }, [isActive, updateTabbableNodes]);

  useEffect(() => {
    return () => {
      const { current } = previousFocusedNode;
      if (current && returnFocus) current.focus();
    };
  }, [returnFocus]);

  const handleKeydown = useCallback(
    (event: React.KeyboardEvent) => {
      const { key, shiftKey } = event;

      let getTabbableNodes = tabbableNodes;
      if (updateNodes) {
        getTabbableNodes = updateTabbableNodes();
      }

      if (key === "Tab" && getTabbableNodes.length) {
        const firstNode = getTabbableNodes[0];
        const lastNode = getTabbableNodes[getTabbableNodes.length - 1];
        const { activeElement } = window.document;

        if (!getTabbableNodes.includes(activeElement as FocusableElement)) {
          event.preventDefault();
          shiftKey ? lastNode.focus() : firstNode.focus();
        }

        if (shiftKey && activeElement === firstNode) {
          event.preventDefault();
          lastNode.focus();
        }

        if (!shiftKey && activeElement === lastNode) {
          event.preventDefault();
          firstNode.focus();
        }
      }
    },
    [tabbableNodes, updateNodes, updateTabbableNodes],
  );

  useEventListener({
    type: "keydown",
    listener: handleKeydown,
  });

  return node;
};
