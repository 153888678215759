"use client";

import { usePathname } from "next/navigation";
import { useRouter as usePagesRouter } from "next/router";
import { deleteCookie, setCookie } from "@utils";

export type YuLifeLocale =
  | "uk" // United Kingdom (en-UK)
  | "us" // United States (en-US)
  | "za" // South Africa (en-ZA)
  | "jp"; // Japan (ja-JP)

export type StrapiLocale = "en" | "en-US" | "en-ZA" | "ja-JP" | "all";

export const yulifeLocales: YuLifeLocale[] = ["uk", "us", "za", "jp"];

export const strapiLocales: StrapiLocale[] = ["en", "en-US", "en-ZA"];

export const resourceHubLocales: YuLifeLocale[] = ["uk", "us", "za"];

export const yulifeLocalesRegex = new RegExp(`^/(${yulifeLocales.join("|")})`, "i");

export const supportedLanguageCodes = ["en-US", "en-ZA", "en-GB", "ja-JP"] as const;

export type SupportedLanguageCode = (typeof supportedLanguageCodes)[number];

export const useLocale = () => {
  const pagesRouter = usePagesRouter();
  const pathname = usePathname();

  return {
    locale: (pagesRouter?.locale as YuLifeLocale) || "uk",
    path: pathname || "/",
    setLocale: (locale: YuLifeLocale) => {
      if (isSpider()) {
        deleteCookie("NEXT_LOCALE");
      } else {
        setCookie("NEXT_LOCALE", locale || "uk", 365);
      }
    },
  };
};

/**
 * User agent sniffing is generally discouraged, however Google and other major search engines continue to recommend specifying a user agent in "robots.txt" files for crawler-specific behavior:
 *  - https://developers.google.com/search/docs/crawling-indexing/robots/create-robots-txt
 *  - https://developers.google.com/search/docs/advanced/crawling/overview-google-crawlers
 *
 * Note: This is not a foolproof method of detecting bots, as anyone can easily spoof their user agent. However, spoofing is not a problem for our use-case, as we are only using this to determine whether or not to set a cookie - the "NEXT_LOCALE" cookie, which will help us redirect users (but not bots!) to a specific region. In other words, if a user wants to pretend to be a bot, they can do so, and we just won't redirect them to a specific region.
 */
function isSpider(): boolean {
  if (typeof window === "undefined" || typeof navigator === "undefined") {
    return false;
  }

  const userAgent = navigator.userAgent.toLowerCase();
  return /bot|crawl|spider|google|baidu|bing|msn|duckduck|teoma|slurp|yandex/.test(userAgent);
}
